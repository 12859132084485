import { default as affiliatesdEDu7KwzpdMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/affiliates.vue?macro=true";
import { default as database_45viewstPm4JhIgZiMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/database-views.vue?macro=true";
import { default as discount_45students_45academics_45ngosAQfVDTPcgZMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/discount-students-academics-ngos.vue?macro=true";
import { default as _91slug_939RFiYaspFXMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/features/[slug].vue?macro=true";
import { default as indexDixvxVstgkMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/features/index.vue?macro=true";
import { default as _91slug_93GxZVE6udhcMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/form-templates/[slug].vue?macro=true";
import { default as indexK8EeEkb8p0Meta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/form-templates/index.vue?macro=true";
import { default as _91slug_93BcIeWYARGdMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/form-templates/industries/[slug].vue?macro=true";
import { default as _91slug_93LfEzimZLCoMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/form-templates/types/[slug].vue?macro=true";
import { default as edit5a3uJ04XvIMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as indexRZja0EmWLzMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/forms/[slug]/index.vue?macro=true";
import { default as analyticsJD7tO3mSMgMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/forms/[slug]/show/analytics.vue?macro=true";
import { default as indexLF8Z1dKlnQMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexRuhMniqkXeMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as shareOAz32hOIWgMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as showOF4tvZYmt8Meta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/forms/[slug]/show.vue?macro=true";
import { default as indexSycDJzBtcuMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/forms/create/index.vue?macro=true";
import { default as uploadsbW1juDAiQZMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/forms/uploads.vue?macro=true";
import { default as guides7hVUFBwRVCMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/guides.vue?macro=true";
import { default as homejNWTUFH0NjMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/home.vue?macro=true";
import { default as indexN3KQ823EcCMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/index.vue?macro=true";
import { default as integrationsFfzWRLAPLNMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/integrations.vue?macro=true";
import { default as loginkJcRjlVD0yMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/login.vue?macro=true";
import { default as my_45templatesLQP1pTJ0yaMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/my-templates.vue?macro=true";
import { default as notion_45ambassadors_45influencersmcZgUpjFpjMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/notion-ambassadors-influencers.vue?macro=true";
import { default as _91slug_93nmeDPcrvDHMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/notion-form/[slug].vue?macro=true";
import { default as callbacksHN4o2hyvzMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/notion/callback.vue?macro=true";
import { default as guest_45callback3fkx57MEAmMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/notion/guest-callback.vue?macro=true";
import { default as callbacklmz7s24x2lMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/oauth/callback.vue?macro=true";
import { default as partnerslG6mgVl9dQMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/partners.vue?macro=true";
import { default as _91token_935u6QF3904MMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/password/reset/[token].vue?macro=true";
import { default as indexELG2lJHEeVMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/password/reset/index.vue?macro=true";
import { default as pricing6DsBZ9bBbMMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/pricing.vue?macro=true";
import { default as privacy_45policysq8Y0hsKL9Meta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/privacy-policy.vue?macro=true";
import { default as registeryMLHeDR7PNMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/register.vue?macro=true";
import { default as access_45tokenspfl4AGqanFMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/settings/access-tokens.vue?macro=true";
import { default as accountTou3MQ9c4ZMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/settings/account.vue?macro=true";
import { default as adminkpJj4KSaQPMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/settings/admin.vue?macro=true";
import { default as billingJiQl1u5WMkMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/settings/billing.vue?macro=true";
import { default as connections3LYE20qILbMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/settings/connections.vue?macro=true";
import { default as custom_45domaindKWZWxuEqMMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/settings/custom-domain.vue?macro=true";
import { default as email_45settings2QCUWHL2ThMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/settings/email-settings.vue?macro=true";
import { default as indexqJgL4LhStOMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/settings/index.vue?macro=true";
import { default as password1tp98yffMMMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/settings/password.vue?macro=true";
import { default as profilegIvqyUig87Meta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/settings/profile.vue?macro=true";
import { default as workspacesard295xIoGMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/settings/workspaces.vue?macro=true";
import { default as settingsvYF5NSJAGQMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/settings.vue?macro=true";
import { default as errorAkHMQVzTDIMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/subscriptions/error.vue?macro=true";
import { default as successs9OArjYu2LMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/subscriptions/success.vue?macro=true";
import { default as terms_45conditionsb85E75U8iwMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/terms-conditions.vue?macro=true";
import { default as edit7o3FL6rZyeMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/views/[slug]/edit.vue?macro=true";
import { default as indexZeJg3inhkzMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/views/[slug]/index.vue?macro=true";
import { default as indexGR6RGAtMsAMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/views/[slug]/show/index.vue?macro=true";
import { default as sharexIfQxXr39TMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/views/[slug]/show/share.vue?macro=true";
import { default as show1mlZ0dwhspMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/views/[slug]/show.vue?macro=true";
import { default as indexdaElobV1ndMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/views/create/index.vue?macro=true";
import { default as indexHb4mW62BohMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/views/index.vue?macro=true";
import { default as uploadshwirAlJBtAMeta } from "/codebuild/output/src3930192241/src/notionforms/client/pages/views/uploads.vue?macro=true";
export default [
  {
    name: affiliatesdEDu7KwzpdMeta?.name ?? "affiliates",
    path: affiliatesdEDu7KwzpdMeta?.path ?? "/affiliates",
    meta: affiliatesdEDu7KwzpdMeta || {},
    alias: affiliatesdEDu7KwzpdMeta?.alias || [],
    redirect: affiliatesdEDu7KwzpdMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/affiliates.vue").then(m => m.default || m)
  },
  {
    name: database_45viewstPm4JhIgZiMeta?.name ?? "database-views",
    path: database_45viewstPm4JhIgZiMeta?.path ?? "/database-views",
    meta: database_45viewstPm4JhIgZiMeta || {},
    alias: database_45viewstPm4JhIgZiMeta?.alias || [],
    redirect: database_45viewstPm4JhIgZiMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/database-views.vue").then(m => m.default || m)
  },
  {
    name: discount_45students_45academics_45ngosAQfVDTPcgZMeta?.name ?? "discount-students-academics-ngos",
    path: discount_45students_45academics_45ngosAQfVDTPcgZMeta?.path ?? "/discount-students-academics-ngos",
    meta: discount_45students_45academics_45ngosAQfVDTPcgZMeta || {},
    alias: discount_45students_45academics_45ngosAQfVDTPcgZMeta?.alias || [],
    redirect: discount_45students_45academics_45ngosAQfVDTPcgZMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/discount-students-academics-ngos.vue").then(m => m.default || m)
  },
  {
    name: _91slug_939RFiYaspFXMeta?.name ?? "features-slug",
    path: _91slug_939RFiYaspFXMeta?.path ?? "/features/:slug()",
    meta: _91slug_939RFiYaspFXMeta || {},
    alias: _91slug_939RFiYaspFXMeta?.alias || [],
    redirect: _91slug_939RFiYaspFXMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/features/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexDixvxVstgkMeta?.name ?? "features",
    path: indexDixvxVstgkMeta?.path ?? "/features",
    meta: indexDixvxVstgkMeta || {},
    alias: indexDixvxVstgkMeta?.alias || [],
    redirect: indexDixvxVstgkMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GxZVE6udhcMeta?.name ?? "form-templates-slug",
    path: _91slug_93GxZVE6udhcMeta?.path ?? "/form-templates/:slug()",
    meta: _91slug_93GxZVE6udhcMeta || {},
    alias: _91slug_93GxZVE6udhcMeta?.alias || [],
    redirect: _91slug_93GxZVE6udhcMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/form-templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexK8EeEkb8p0Meta?.name ?? "form-templates",
    path: indexK8EeEkb8p0Meta?.path ?? "/form-templates",
    meta: indexK8EeEkb8p0Meta || {},
    alias: indexK8EeEkb8p0Meta?.alias || [],
    redirect: indexK8EeEkb8p0Meta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/form-templates/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93BcIeWYARGdMeta?.name ?? "form-templates-industries-slug",
    path: _91slug_93BcIeWYARGdMeta?.path ?? "/form-templates/industries/:slug()",
    meta: _91slug_93BcIeWYARGdMeta || {},
    alias: _91slug_93BcIeWYARGdMeta?.alias || [],
    redirect: _91slug_93BcIeWYARGdMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/form-templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LfEzimZLCoMeta?.name ?? "form-templates-types-slug",
    path: _91slug_93LfEzimZLCoMeta?.path ?? "/form-templates/types/:slug()",
    meta: _91slug_93LfEzimZLCoMeta || {},
    alias: _91slug_93LfEzimZLCoMeta?.alias || [],
    redirect: _91slug_93LfEzimZLCoMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/form-templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: edit5a3uJ04XvIMeta?.name ?? "forms-slug-edit",
    path: edit5a3uJ04XvIMeta?.path ?? "/forms/:slug()/edit",
    meta: edit5a3uJ04XvIMeta || {},
    alias: edit5a3uJ04XvIMeta?.alias || [],
    redirect: edit5a3uJ04XvIMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexRZja0EmWLzMeta?.name ?? "forms-slug",
    path: indexRZja0EmWLzMeta?.path ?? "/forms/:slug()",
    meta: indexRZja0EmWLzMeta || {},
    alias: indexRZja0EmWLzMeta?.alias || [],
    redirect: indexRZja0EmWLzMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showOF4tvZYmt8Meta?.name ?? undefined,
    path: showOF4tvZYmt8Meta?.path ?? "/forms/:slug()/show",
    meta: showOF4tvZYmt8Meta || {},
    alias: showOF4tvZYmt8Meta?.alias || [],
    redirect: showOF4tvZYmt8Meta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: analyticsJD7tO3mSMgMeta?.name ?? "forms-slug-show-analytics",
    path: analyticsJD7tO3mSMgMeta?.path ?? "analytics",
    meta: analyticsJD7tO3mSMgMeta || {},
    alias: analyticsJD7tO3mSMgMeta?.alias || [],
    redirect: analyticsJD7tO3mSMgMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/forms/[slug]/show/analytics.vue").then(m => m.default || m)
  },
  {
    name: indexLF8Z1dKlnQMeta?.name ?? "forms-slug-show",
    path: indexLF8Z1dKlnQMeta?.path ?? "",
    meta: indexLF8Z1dKlnQMeta || {},
    alias: indexLF8Z1dKlnQMeta?.alias || [],
    redirect: indexLF8Z1dKlnQMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: indexRuhMniqkXeMeta?.name ?? "forms-slug-show-integrations",
    path: indexRuhMniqkXeMeta?.path ?? "integrations",
    meta: indexRuhMniqkXeMeta || {},
    alias: indexRuhMniqkXeMeta?.alias || [],
    redirect: indexRuhMniqkXeMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: shareOAz32hOIWgMeta?.name ?? "forms-slug-show-share",
    path: shareOAz32hOIWgMeta?.path ?? "share",
    meta: shareOAz32hOIWgMeta || {},
    alias: shareOAz32hOIWgMeta?.alias || [],
    redirect: shareOAz32hOIWgMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexSycDJzBtcuMeta?.name ?? "forms-create",
    path: indexSycDJzBtcuMeta?.path ?? "/forms/create",
    meta: indexSycDJzBtcuMeta || {},
    alias: indexSycDJzBtcuMeta?.alias || [],
    redirect: indexSycDJzBtcuMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: uploadsbW1juDAiQZMeta?.name ?? "forms-uploads",
    path: uploadsbW1juDAiQZMeta?.path ?? "/forms/uploads",
    meta: uploadsbW1juDAiQZMeta || {},
    alias: uploadsbW1juDAiQZMeta?.alias || [],
    redirect: uploadsbW1juDAiQZMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/forms/uploads.vue").then(m => m.default || m)
  },
  {
    name: guides7hVUFBwRVCMeta?.name ?? "guides",
    path: guides7hVUFBwRVCMeta?.path ?? "/guides",
    meta: guides7hVUFBwRVCMeta || {},
    alias: guides7hVUFBwRVCMeta?.alias || [],
    redirect: guides7hVUFBwRVCMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/guides.vue").then(m => m.default || m)
  },
  {
    name: homejNWTUFH0NjMeta?.name ?? "home",
    path: homejNWTUFH0NjMeta?.path ?? "/home",
    meta: homejNWTUFH0NjMeta || {},
    alias: homejNWTUFH0NjMeta?.alias || [],
    redirect: homejNWTUFH0NjMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexN3KQ823EcCMeta?.name ?? "index",
    path: indexN3KQ823EcCMeta?.path ?? "/",
    meta: indexN3KQ823EcCMeta || {},
    alias: indexN3KQ823EcCMeta?.alias || [],
    redirect: indexN3KQ823EcCMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: integrationsFfzWRLAPLNMeta?.name ?? "integrations",
    path: integrationsFfzWRLAPLNMeta?.path ?? "/integrations",
    meta: integrationsFfzWRLAPLNMeta || {},
    alias: integrationsFfzWRLAPLNMeta?.alias || [],
    redirect: integrationsFfzWRLAPLNMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/integrations.vue").then(m => m.default || m)
  },
  {
    name: loginkJcRjlVD0yMeta?.name ?? "login",
    path: loginkJcRjlVD0yMeta?.path ?? "/login",
    meta: loginkJcRjlVD0yMeta || {},
    alias: loginkJcRjlVD0yMeta?.alias || [],
    redirect: loginkJcRjlVD0yMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: my_45templatesLQP1pTJ0yaMeta?.name ?? "my-templates",
    path: my_45templatesLQP1pTJ0yaMeta?.path ?? "/my-templates",
    meta: my_45templatesLQP1pTJ0yaMeta || {},
    alias: my_45templatesLQP1pTJ0yaMeta?.alias || [],
    redirect: my_45templatesLQP1pTJ0yaMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/my-templates.vue").then(m => m.default || m)
  },
  {
    name: notion_45ambassadors_45influencersmcZgUpjFpjMeta?.name ?? "notion-ambassadors-influencers",
    path: notion_45ambassadors_45influencersmcZgUpjFpjMeta?.path ?? "/notion-ambassadors-influencers",
    meta: notion_45ambassadors_45influencersmcZgUpjFpjMeta || {},
    alias: notion_45ambassadors_45influencersmcZgUpjFpjMeta?.alias || [],
    redirect: notion_45ambassadors_45influencersmcZgUpjFpjMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/notion-ambassadors-influencers.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nmeDPcrvDHMeta?.name ?? "notion-form-slug",
    path: _91slug_93nmeDPcrvDHMeta?.path ?? "/notion-form/:slug()",
    meta: _91slug_93nmeDPcrvDHMeta || {},
    alias: _91slug_93nmeDPcrvDHMeta?.alias || [],
    redirect: _91slug_93nmeDPcrvDHMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/notion-form/[slug].vue").then(m => m.default || m)
  },
  {
    name: callbacksHN4o2hyvzMeta?.name ?? "notion-callback",
    path: callbacksHN4o2hyvzMeta?.path ?? "/notion/callback",
    meta: callbacksHN4o2hyvzMeta || {},
    alias: callbacksHN4o2hyvzMeta?.alias || [],
    redirect: callbacksHN4o2hyvzMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/notion/callback.vue").then(m => m.default || m)
  },
  {
    name: guest_45callback3fkx57MEAmMeta?.name ?? "notion-guest-callback",
    path: guest_45callback3fkx57MEAmMeta?.path ?? "/notion/guest-callback",
    meta: guest_45callback3fkx57MEAmMeta || {},
    alias: guest_45callback3fkx57MEAmMeta?.alias || [],
    redirect: guest_45callback3fkx57MEAmMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/notion/guest-callback.vue").then(m => m.default || m)
  },
  {
    name: callbacklmz7s24x2lMeta?.name ?? "oauth-callback",
    path: callbacklmz7s24x2lMeta?.path ?? "/oauth/callback",
    meta: callbacklmz7s24x2lMeta || {},
    alias: callbacklmz7s24x2lMeta?.alias || [],
    redirect: callbacklmz7s24x2lMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: partnerslG6mgVl9dQMeta?.name ?? "partners",
    path: partnerslG6mgVl9dQMeta?.path ?? "/partners",
    meta: partnerslG6mgVl9dQMeta || {},
    alias: partnerslG6mgVl9dQMeta?.alias || [],
    redirect: partnerslG6mgVl9dQMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: _91token_935u6QF3904MMeta?.name ?? "password-reset-token",
    path: _91token_935u6QF3904MMeta?.path ?? "/password/reset/:token()",
    meta: _91token_935u6QF3904MMeta || {},
    alias: _91token_935u6QF3904MMeta?.alias || [],
    redirect: _91token_935u6QF3904MMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: indexELG2lJHEeVMeta?.name ?? "password-reset",
    path: indexELG2lJHEeVMeta?.path ?? "/password/reset",
    meta: indexELG2lJHEeVMeta || {},
    alias: indexELG2lJHEeVMeta?.alias || [],
    redirect: indexELG2lJHEeVMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/password/reset/index.vue").then(m => m.default || m)
  },
  {
    name: pricing6DsBZ9bBbMMeta?.name ?? "pricing",
    path: pricing6DsBZ9bBbMMeta?.path ?? "/pricing",
    meta: pricing6DsBZ9bBbMMeta || {},
    alias: pricing6DsBZ9bBbMMeta?.alias || [],
    redirect: pricing6DsBZ9bBbMMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policysq8Y0hsKL9Meta?.name ?? "privacy-policy",
    path: privacy_45policysq8Y0hsKL9Meta?.path ?? "/privacy-policy",
    meta: privacy_45policysq8Y0hsKL9Meta || {},
    alias: privacy_45policysq8Y0hsKL9Meta?.alias || [],
    redirect: privacy_45policysq8Y0hsKL9Meta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: registeryMLHeDR7PNMeta?.name ?? "register",
    path: registeryMLHeDR7PNMeta?.path ?? "/register",
    meta: registeryMLHeDR7PNMeta || {},
    alias: registeryMLHeDR7PNMeta?.alias || [],
    redirect: registeryMLHeDR7PNMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingsvYF5NSJAGQMeta?.name ?? undefined,
    path: settingsvYF5NSJAGQMeta?.path ?? "/settings",
    meta: settingsvYF5NSJAGQMeta || {},
    alias: settingsvYF5NSJAGQMeta?.alias || [],
    redirect: settingsvYF5NSJAGQMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: access_45tokenspfl4AGqanFMeta?.name ?? "settings-access-tokens",
    path: access_45tokenspfl4AGqanFMeta?.path ?? "access-tokens",
    meta: access_45tokenspfl4AGqanFMeta || {},
    alias: access_45tokenspfl4AGqanFMeta?.alias || [],
    redirect: access_45tokenspfl4AGqanFMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/settings/access-tokens.vue").then(m => m.default || m)
  },
  {
    name: accountTou3MQ9c4ZMeta?.name ?? "settings-account",
    path: accountTou3MQ9c4ZMeta?.path ?? "account",
    meta: accountTou3MQ9c4ZMeta || {},
    alias: accountTou3MQ9c4ZMeta?.alias || [],
    redirect: accountTou3MQ9c4ZMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: adminkpJj4KSaQPMeta?.name ?? "settings-admin",
    path: adminkpJj4KSaQPMeta?.path ?? "admin",
    meta: adminkpJj4KSaQPMeta || {},
    alias: adminkpJj4KSaQPMeta?.alias || [],
    redirect: adminkpJj4KSaQPMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: billingJiQl1u5WMkMeta?.name ?? "settings-billing",
    path: billingJiQl1u5WMkMeta?.path ?? "billing",
    meta: billingJiQl1u5WMkMeta || {},
    alias: billingJiQl1u5WMkMeta?.alias || [],
    redirect: billingJiQl1u5WMkMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: connections3LYE20qILbMeta?.name ?? "settings-connections",
    path: connections3LYE20qILbMeta?.path ?? "connections",
    meta: connections3LYE20qILbMeta || {},
    alias: connections3LYE20qILbMeta?.alias || [],
    redirect: connections3LYE20qILbMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: custom_45domaindKWZWxuEqMMeta?.name ?? "settings-custom-domain",
    path: custom_45domaindKWZWxuEqMMeta?.path ?? "custom-domain",
    meta: custom_45domaindKWZWxuEqMMeta || {},
    alias: custom_45domaindKWZWxuEqMMeta?.alias || [],
    redirect: custom_45domaindKWZWxuEqMMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/settings/custom-domain.vue").then(m => m.default || m)
  },
  {
    name: email_45settings2QCUWHL2ThMeta?.name ?? "settings-email-settings",
    path: email_45settings2QCUWHL2ThMeta?.path ?? "email-settings",
    meta: email_45settings2QCUWHL2ThMeta || {},
    alias: email_45settings2QCUWHL2ThMeta?.alias || [],
    redirect: email_45settings2QCUWHL2ThMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/settings/email-settings.vue").then(m => m.default || m)
  },
  {
    name: indexqJgL4LhStOMeta?.name ?? "settings",
    path: indexqJgL4LhStOMeta?.path ?? "",
    meta: indexqJgL4LhStOMeta || {},
    alias: indexqJgL4LhStOMeta?.alias || [],
    redirect: indexqJgL4LhStOMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: password1tp98yffMMMeta?.name ?? "settings-password",
    path: password1tp98yffMMMeta?.path ?? "password",
    meta: password1tp98yffMMMeta || {},
    alias: password1tp98yffMMMeta?.alias || [],
    redirect: password1tp98yffMMMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: profilegIvqyUig87Meta?.name ?? "settings-profile",
    path: profilegIvqyUig87Meta?.path ?? "profile",
    meta: profilegIvqyUig87Meta || {},
    alias: profilegIvqyUig87Meta?.alias || [],
    redirect: profilegIvqyUig87Meta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: workspacesard295xIoGMeta?.name ?? "settings-workspaces",
    path: workspacesard295xIoGMeta?.path ?? "workspaces",
    meta: workspacesard295xIoGMeta || {},
    alias: workspacesard295xIoGMeta?.alias || [],
    redirect: workspacesard295xIoGMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/settings/workspaces.vue").then(m => m.default || m)
  }
]
  },
  {
    name: errorAkHMQVzTDIMeta?.name ?? "subscriptions-error",
    path: errorAkHMQVzTDIMeta?.path ?? "/subscriptions/error",
    meta: errorAkHMQVzTDIMeta || {},
    alias: errorAkHMQVzTDIMeta?.alias || [],
    redirect: errorAkHMQVzTDIMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: successs9OArjYu2LMeta?.name ?? "subscriptions-success",
    path: successs9OArjYu2LMeta?.path ?? "/subscriptions/success",
    meta: successs9OArjYu2LMeta || {},
    alias: successs9OArjYu2LMeta?.alias || [],
    redirect: successs9OArjYu2LMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsb85E75U8iwMeta?.name ?? "terms-conditions",
    path: terms_45conditionsb85E75U8iwMeta?.path ?? "/terms-conditions",
    meta: terms_45conditionsb85E75U8iwMeta || {},
    alias: terms_45conditionsb85E75U8iwMeta?.alias || [],
    redirect: terms_45conditionsb85E75U8iwMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: edit7o3FL6rZyeMeta?.name ?? "views-slug-edit",
    path: edit7o3FL6rZyeMeta?.path ?? "/views/:slug()/edit",
    meta: edit7o3FL6rZyeMeta || {},
    alias: edit7o3FL6rZyeMeta?.alias || [],
    redirect: edit7o3FL6rZyeMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/views/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexZeJg3inhkzMeta?.name ?? "views-slug",
    path: indexZeJg3inhkzMeta?.path ?? "/views/:slug()",
    meta: indexZeJg3inhkzMeta || {},
    alias: indexZeJg3inhkzMeta?.alias || [],
    redirect: indexZeJg3inhkzMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/views/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: show1mlZ0dwhspMeta?.name ?? undefined,
    path: show1mlZ0dwhspMeta?.path ?? "/views/:slug()/show",
    meta: show1mlZ0dwhspMeta || {},
    alias: show1mlZ0dwhspMeta?.alias || [],
    redirect: show1mlZ0dwhspMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/views/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: indexGR6RGAtMsAMeta?.name ?? "views-slug-show",
    path: indexGR6RGAtMsAMeta?.path ?? "",
    meta: indexGR6RGAtMsAMeta || {},
    alias: indexGR6RGAtMsAMeta?.alias || [],
    redirect: indexGR6RGAtMsAMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/views/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: sharexIfQxXr39TMeta?.name ?? "views-slug-show-share",
    path: sharexIfQxXr39TMeta?.path ?? "share",
    meta: sharexIfQxXr39TMeta || {},
    alias: sharexIfQxXr39TMeta?.alias || [],
    redirect: sharexIfQxXr39TMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/views/[slug]/show/share.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexdaElobV1ndMeta?.name ?? "views-create",
    path: indexdaElobV1ndMeta?.path ?? "/views/create",
    meta: indexdaElobV1ndMeta || {},
    alias: indexdaElobV1ndMeta?.alias || [],
    redirect: indexdaElobV1ndMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/views/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexHb4mW62BohMeta?.name ?? "views",
    path: indexHb4mW62BohMeta?.path ?? "/views",
    meta: indexHb4mW62BohMeta || {},
    alias: indexHb4mW62BohMeta?.alias || [],
    redirect: indexHb4mW62BohMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/views/index.vue").then(m => m.default || m)
  },
  {
    name: uploadshwirAlJBtAMeta?.name ?? "views-uploads",
    path: uploadshwirAlJBtAMeta?.path ?? "/views/uploads",
    meta: uploadshwirAlJBtAMeta || {},
    alias: uploadshwirAlJBtAMeta?.alias || [],
    redirect: uploadshwirAlJBtAMeta?.redirect,
    component: () => import("/codebuild/output/src3930192241/src/notionforms/client/pages/views/uploads.vue").then(m => m.default || m)
  }
]